/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import queryString from "query-string"
import NoSSR from "react-no-ssr"
import IRPageWrapper from "../../../layouts/IRPageWrapper"
import C18 from "./calendarimg/2018calendar.jpg"
import CalendarNav from "../../../components/CalendarNav"
import IRBG from "../../../components/IRBG"
import IRNav from "../../../components/IRNav"
import HomeLogo from "../../../components/HomeLogo"

export default class CalendarPage extends Component {
  render() {
    const { allMarkdownRemark } = this.props.data
    const events = allMarkdownRemark ? allMarkdownRemark.edges : []
    // const latestYear = events.length > 0 ? events[0].node.frontmatter.year : null
    const query = queryString.parse(this.props.location.search)
    const year = query.year
      ? query.year
      : events.length > 0
      ? events[0].node.frontmatter.year
      : null

    const years = []
    events.map((node) => {
      if (!(years.indexOf(node.node.frontmatter.year) >= 0)) {
        years.push(node.node.frontmatter.year)
      }
    })

    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <title>Calendar - EA Technique</title>
        </Helmet>
        <section
          className="hero is-fullheight"
          style={{ display: `inline-block` }}
        >
          <section className="hero is-small">
            <div className="hero-body">
              <div className="columns">
                <div className="column is-offset-1">
                  <div className="section">
                    <HomeLogo />
                    <h1 className="title" id="title-line">
                      Corporate Calendar
                    </h1>
                    <IRNav />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section">
            <NoSSR>
              <CalendarNav years={years} currentYear={year} />
              <div className="columns is-multiline">
                {events.map((node, index) => {
                  const event = node.node
                  if (event.frontmatter.year == year) {
                    return (
                      <div className="column is-12 is-offset-2">
                        <div className="columns" id="calendar-line">
                          <div className="column is-1">
                            <img src={event.frontmatter.image} />
                          </div>
                          <div className="column">
                            <h1 className="subtitle">
                              {event.frontmatter.title}
                            </h1>
                            {/* <p>
                            Date of Meeting : 14 May 2018
                          <br />Time : 12:00 PM
                          <br />Venue : Permata Ballroom, Level B2
                          <br />The Puteri Pacific Johor Bahru
                          <br />Jalan Abdullah Ibrahim
                          <br />80000 Johor Bahru, Johor
                          </p> */}
                            {/* <div>{event.html}</div> */}
                            <div
                              dangerouslySetInnerHTML={{ __html: event.html }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }
                })}

                {/* <div className="column is-12 is-offset-2">
                <div className="columns" id="calendar-line">
                  <div className="column is-1">
                    <img src={C18} />
                  </div>
                  <div className="column">
                    <h1 className="subtitle">24th Annual General Meeting ("AGM") of E.A. Technique (M) Berhad</h1>
                    <p>
                      Date of Meeting : 14 May 2018
                    <br />Time : 12:00 PM
                    <br />Venue : Permata Ballroom, Level B2
                    <br />The Puteri Pacific Johor Bahru
                    <br />Jalan Abdullah Ibrahim
                    <br />80000 Johor Bahru, Johor
                    </p>
                  </div>
                </div>
              </div>

              <div className="column is-12 is-offset-2">
                <div className="columns" id="calendar-line">
                  <div className="column is-1">
                    <img src={C18} />
                  </div>
                  <div className="column">
                    <h1 className="subtitle">24th Annual General Meeting ("AGM") of E.A. Technique (M) Berhad</h1>
                    <p>
                      Date of Meeting : 14 May 2018
                    <br />Time : 12:00 PM
                    <br />Venue : Permata Ballroom, Level B2
                    <br />The Puteri Pacific Johor Bahru
                    <br />Jalan Abdullah Ibrahim
                    <br />80000 Johor Bahru, Johor
                    </p>
                  </div>
                </div>
              </div> */}
              </div>
            </NoSSR>
          </section>
        </section>
      </IRPageWrapper>
    )
  }
}

CalendarPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const calendarPageQuery = graphql`
  query CalendarPageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "calendar-page" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          html
          frontmatter {
            year
            title
            image
            date
          }
        }
      }
    }
  }
`
